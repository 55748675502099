html, body {
  /* turn off the overscroll/bounce effect when the browser reaches the boundary of a scrolling area */
  /* overscroll-behavior-y works for non-Safari browsers */
  overscroll-behavior-y: none;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.image-small {
  height: 40px;
}

.styled-input {
  box-sizing: border-box;
  height: 48px;
  width: 100%;
  margin: 0px;
  border: none;
  background: #17213A;
  color: white;
  font-family: Armata;
  font-size: 16px;
  padding: 0px 8px;
  text-align: end;
}

.styled-input::placeholder {
  color: #556284;
  text-align: end;
}

.border-white {
  border: 1px white solid;
}

.input-transparent {
  background: none;
  border: none;
  border-bottom: 1px solid black;
  width: 60%;
}

.ubuntu {
  font-family: 'Ubuntu';
}

.white {
  color: white;
}

.black {
  color: black;
}

.bold {
  font-weight: bold!important;
}

.fw-light {
  font-weight: 200;
}

.block {
  display: block;
}

.text-start {
  text-align: start;
}

.text-start::placeholder {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-end {
  text-align: end;
}

.w-30 {
  width: 30%;
  max-width: 30%;
}

.w-70 {
  width: 70%;
  max-width: 70%;
}

.w-100 {
  width: 100%;
  max-width: 100%;
}

.fit-screen {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-8 {
  flex-grow: 8;
}

.flex {
  display: flex;
}

.flex div, .flex button {
  flex: 1;
  align-self: stretch;
  min-width: 0;
}

.flex-start {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-vertical-center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-vertical {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-vertical-end {
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;
  box-sizing: border-box;
}

.flex-stretch {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  justify-content: space-between;
}

.flex-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}

.align-items-stretch {
  align-items: stretch;
}

.align-items-start {
  align-items: start;
}

.justify-start {
  justify-content: start;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.grid-2-col {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 12px;
  grid-row-gap: 12px;
}

.grid-col-span-2 {
  grid-column: span 2;
}

.h-4 {
  height: 48px;
}

.h-12 {
  height: 144px;
}

.h-90 {
  height: 90%;
}

.h-10 {
  height: 10%;
}

.h-30vh {
  height: 30vh;
}

.h-40vh {
  height: 40vh;
}

.h-min-100vh {
  min-height: 100vh;
  box-sizing: border-box;
}

.h-100 {
  height: 100%;
}

.m-0-svg-container svg {
  margin: 0px!important;
}

.m-auto {
  display: block;
  margin: 0px auto;
}

.m-1 {
  margin: 12px;
}

.mt-1 {
  margin-top: 12px;
}

.mt-2 {
  margin-top: 24px;
}

.mt-3 {
  margin-top: 36px;
}

.mt-4 {
  margin-top: 48px;
}

.ml-tiny {
  margin-left: 4px;
}

.mr-0 {
  margin-right: 0px!important;
}

.mr-1 {
  margin-right: 12px;
}

.ml-0 {
  margin-left: 0px!important;
}

.ml-1 {
  margin-left: 12px;
}

.ml-neg-4 {
  margin-left: -48px;
}

.mb-tiny {
  margin-bottom: 4px;
}

.mb-1 {
  margin-bottom: 12px;
}

.mb-0 {
  margin-bottom: 0px!important;
}


.mb-2 {
  margin-bottom: 24px;
}

.mb-3 {
  margin-bottom: 36px;
}

.mb-4 {
  margin-bottom: 48px;
}

.mb-8 {
  margin-bottom: 96px;
}

.m-1-0 {
  margin: 12px 0px!important;
}

.pr-4-5 {
  padding-right: 54px;
}

.p-0-2 {
  padding: 0px 24px;
}

.p-0-4 {
  padding: 0px 48px;
}

.p-0-8 {
  padding: 0px 96px;
}

.p-4-0 {
  padding: 48px 0px;
}

.p-0 {
  padding: 0px!important;
}

.p-1 {
  padding: 12px!important;
}

.p-2 {
  padding: 24px!important;
}

.p-5 {
  padding: 60px!important;
}

.p-3-2 {
  padding: 36px 24px!important;
}

.pb-8 {
  padding-bottom: 96px!important;
}

.datepicker-override-styles svg {
  fill: white;
  color: white;
}

.container-padding {
  padding: 48px 0px;
}

.f-1-5 {
  font-size: 1.5rem;
}

.f-2 {
  font-size: 2rem;
}

.f-3 {
  font-size: 3rem;
}

.ls-0 {
  text-indent: 0rem;
  letter-spacing: 0rem;
}

.ls-04 {
  text-indent: 0.4rem;
  letter-spacing: 0.4rem;
}

.ls-08 {
  text-indent: 0.8rem;
  letter-spacing: 0.8rem;
}

.ls-1 {
  text-indent: 1rem;
  letter-spacing: 1rem;
}

.icon-small {
  width: 24px;
}

.relative {
  position: relative;
}

.navbar {
  height: 96px;
  position: fixed;
  bottom: 0px;
}

.upper-right {
  position: absolute;
  top: 10px;
  right: 10px;
}

.container-rounded-top {
  background: white;
  color: black;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  padding-top: 24px;
  min-height: 70vh;
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.responsive-width-100-50 {
  width: 100%;
  max-width: 100vw;
}

@media (min-width:900px) {
  /* hi-res laptops and desktops */
  .fixed-width-desktop {
    max-width: 50vw;
    margin: 0 auto;
  }
  .responsive-width-100-50, .navbar {
    width: 50vw;
  }
}


.reset, .reset p, .reset button {
  color: black;
  letter-spacing: 0rem;
}

.pointer:hover {
  cursor: pointer;
}


/* reset */

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}